/***
FuelUX Spinners
***/

.spinner-buttons.btn-group-vertical .btn {
  text-align: center;
  margin: 0;
  height: 17px;
  width: 22px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0px;

  .page-style-rounded & {
  	 &.spinner-up {
		border-radius: 0;
	  	border-top-right-radius: $general-border-radius !important;
	  }    

	  &.spinner-down {     
	  	border-radius: 0;
	  	border-bottom-right-radius: $general-border-radius;      
	  }
	} 
} 

