/***
Gritter notification modify
***/

#gritter-notice-wrapper {
  right:1px !important;
}

.gritter-close {
  left:auto !important;
  right: 3px !important;
}

.gritter-title {
  font-family:  'Open Sans' ;
  font-size: 18px ;
  font-weight: 300 ;
}