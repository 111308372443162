/***
jQuery File Upload
***/

.blueimp-gallery .close {  
  background-image: url("../img/portlet-remove-icon-white.png") !important;
  margin-top: -2px;
}

.blueimp-gallery .prev,
.blueimp-gallery .next {
  border-radius: 23px !important;
}