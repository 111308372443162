/***
Google reCaptcha
***/
.form-recaptcha-img {
  margin-bottom: 10px;
  clear: both;
  border: 1px solid #e5e5e5;
  padding: 5px;
}

iframe[src="about:blank"] {
  display:none;
}