/***
Prism Plugin
***/

pre[class*="language-"] {
	padding: 10px;
	background: none !important;
	border: 1px solid $general-panel-bg-color !important;
	box-shadow: none !important;
	margin: 0;

	code {
		background: none !important;
		box-shadow: none !important;
		border: 0 !important;
	}
}